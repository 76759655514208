import { setRemoteDefinitions } from '@nx/angular/mf';

import { environment } from './environments/environment';

const mfSettings = JSON.parse(localStorage.getItem('mf')) || {};
const isDev =
  !environment.production && environment.environment === 'development';

const getHost = (app: string) =>
  isDev
    ? mfSettings[app] || environment.apps[app].host
    : environment.apps[app].host;

const definitions = Object.keys(environment.apps).reduce(
  (meta, key) => ({
    ...meta,
    [key]:
      getHost(key) + `/remoteEntry.mjs?sessionId=${new Date().getTime()}.mjs`,
  }),
  {}
);

setRemoteDefinitions(definitions);

// eslint-disable-next-line no-console
import('./bootstrap').catch((err) => console.error(err));
